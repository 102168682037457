import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Carousel.css";

// import required modules
import { Navigation, Pagination } from "swiper";
import { NavLink } from "react-router-dom";

export default function Carousel() {
  //algo no funciona jeje

  // const changeScroll = () => {
  //   if ((window.scrollX = 0)) {
  //     setBigger(true);
  //   } else {
  //     setBigger(false);
  //   }
  // };
  // window.addEventListener("scroll", changeScroll);
  //fin

  return (
    <div className="contenedor-carrusel">
      <Swiper
        navigation={true}
        slidesPerView={3}
        spaceBetween={50}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper just-web"
      >
        <SwiperSlide>
          <div className="slide-div">
            {" "}
            <div className="slide-div">
              <img
                src={require("./images/tupi-f900m.png")}
                alt=""
                className="img-carousel"
              />
              <h2 className="nombre-producto">Tupí F 900 M</h2>
              <a
                href="https://www.felder-group.com/es-uy/productos/tupis-c1949/tupi-con-eje-inclinable-f-900-m-p3233"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="product-button">VER CARACTERÍSTICAS</button>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-div">
            <img
              src={require("./images/sierra-de-cinta.png")}
              alt=""
              className="img-carousel"
            />
            <h2 className="nombre-producto">Sierra de cinta FB940</h2>
            <a
              href="https://www.felder-group.com/es-uy/productos/sierras-de-cinta-c1958/sierra-de-cinta-industrial-line-p142952"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="product-button"> VER CARACTERÍSTICAS </button>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-div">
            <img
              src={require("./images/cnc-express.png")}
              alt=""
              className="img-carousel"
            />
            <h2 className="nombre-producto">CNC c-express 920 classic</h2>
            <a
              href="https://www.felder-group.com/es-uy/productos/centros-cnc-c1953/centros-cnc-c-express-920-classic-p3299"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="product-button"> VER CARACTERÍSTICAS </button>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-div">
            <img
              src={require("./images/combinada-c331.png")}
              alt=""
              className="img-carousel"
            />
            <h2 className="nombre-producto">Combinada C3 31</h2>
            <a
              href="https://www.felder-group.com/es-uy/productos/combinadas-de-5-operaciones-c1952/maquina-combinada-c3-31-p142825"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="product-button"> VER CARACTERÍSTICAS </button>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-div">
            <img
              src={require("./images/regruesadora.png")}
              alt=""
              className="img-carousel"
            />
            <h2 className="nombre-producto">Regresuadora Exact 63</h2>
            <a
              href="https://www.felder-group.com/es-uy/productos/cepilladoras-regruesadoras-c1948/regruesadora-exact-63-p3067"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="product-button"> VER CARACTERÍSTICAS </button>
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
      <Swiper
        navigation={true}
        slidesPerView={1}
        spaceBetween={50}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper just-mobile"
      >
        <SwiperSlide>
          <div className="slide-div">
            {" "}
            <div className="slide-div">
              <img
                src={require("./images/tupi-f900m.png")}
                alt=""
                className="img-carousel"
              />
              <h2 className="nombre-producto">Tupí F 900 M</h2>
              <NavLink to="/contact">
                <button className="product-button">
                  {" "}
                  VER CARACTERÍSTICAS{" "}
                </button>
              </NavLink>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-div">
            <img
              src={require("./images/sierra-de-cinta.png")}
              alt=""
              className="img-carousel"
            />
            <h2 className="nombre-producto">Sierra de cinta FB940</h2>
            <NavLink to="/contact">
              <button className="product-button"> VER CARACTERÍSTICAS </button>
            </NavLink>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-div">
            <img
              src={require("./images/cnc-express.png")}
              alt=""
              className="img-carousel"
            />
            <h2 className="nombre-producto">CNC c-express 920 classic</h2>
            <NavLink to="/contact">
              <button className="product-button"> VER CARACTERÍSTICAS </button>
            </NavLink>
          </div>
        </SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
      </Swiper>
    </div>
  );
}
