import React, { useState } from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";

export default function Contact() {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [messPhone, setMessPhone] = useState("");

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError(true);
    } else {
      setError(false);
    }

    setMessage(event.target.value);
  };

  function validatePhoneNumber(input_str) {
    return /^.{9,9}$/.test(input_str);
  }

  const handlePhone = (event) => {
    if (!validatePhoneNumber(event.target.value)) {
      setErrorPhone(true);
    } else {
      setErrorPhone(false);
    }
    setMessPhone(event.target.value);
  };

  return (
    <div className="total">
      <div className="contact-component">
        <h1 className="just-web">
          ¿Listo para llevar tu taller <br /> al siguiente nivel?
        </h1>
        <h1 className="just-mobile">
          ¿Listo para <br /> llevar tu taller al <br /> siguiente nivel?
        </h1>
        <h2>Contáctese con ALP Importaciones</h2>
        <form
          action="https://getform.io/f/e1e577df-9297-49eb-99c7-ce671e9f2b2f"
          method="post"
          className="contact-form"
        >
          <div className="container-label">
            <label htmlFor="">Nombre y apellido</label>
            <div className="container-input">
              <input
                className="noterror-form"
                type="text"
                name="name"
                id=""
                placeholder="Juan Pérez"
                required
              />
            </div>
          </div>
          <div className="container-label">
            <label htmlFor="">E-mail</label>
            <div className="container-input">
              <input
                className={error ? "error-form" : "noterror-form"}
                type="email"
                name="email"
                id=""
                placeholder="email@email.com"
                required
                value={message}
                onChange={handleChange}
              />
              {error && <p className="mensaje-error">Ingrese mail válido</p>}
            </div>
          </div>
          <div className="container-label">
            <label htmlFor="">Número de contacto</label>
            <div className="container-input">
              <input
                className={errorPhone ? "error-form" : "noterror-form"}
                type="number"
                name="celular"
                id=""
                placeholder="099 999 999"
                required
                value={messPhone}
                onChange={handlePhone}
              />
              {errorPhone && (
                <p className="mensaje-error">Ingrese celular válido</p>
              )}
            </div>
          </div>
          <div className="container-label">
            <label htmlFor="">Mensaje</label>
            <div>
              <textarea
                name="mensaje"
                id=""
                cols="30"
                rows="7"
                placeholder="Escriba aquí su consulta"
                required
              ></textarea>
            </div>
          </div>

          <button type="submit" className="contact-button">
            ENVIAR FORMULARIO
            {/* <input
              type="submit"
              name=""
              id=""
              placeholder="Enviar formulario"
            /> */}
          </button>
        </form>
      </div>
      <hr />
      <footer>
        <p>Copyright 2022</p>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/ALPImportaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="https://www.instagram.com/alp_importaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>

          <a
            href="https://www.linkedin.com/company/alp-importaciones/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </footer>
    </div>
  );
}
