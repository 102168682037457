import {
  BrowserRouter,
  Navigate,
  NavLink,
  Route,
  Routes,
} from "react-router-dom";
import AboutUs from "./AboutUs";
import "./Home.css";
import "./Contact.css";
import "./AboutUs.css";
import "./Products.css";
import Contact from "./Contact";
import Home from "./Home";
import Navbar from "./Navbar";
import Products from "./Products";
import { useState } from "react";
import ScrollToTop from "./ScrollToTop";
import Slidebar from "./Slidebar";
import "./HomeMobile.css";
import "./ProductsMobile.css";
import "./ContactMobile.css";
import "./AboutUsMobile.css";

function App() {
  const [show, setShow] = useState(false);
  const changeScroll = () => {
    if (window.scrollY >= 780) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  window.addEventListener("scroll", changeScroll);

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <header className={show ? "header active" : "header"}>
          <button>
            <NavLink to="/home">
              <img
                src={require("./images/Group.png")}
                alt=""
                className="alp-logo"
              />
            </NavLink>
          </button>
          <Navbar className="navBar" />
        </header>
        <div className="just-mobile">
          <Slidebar />
        </div>

        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
