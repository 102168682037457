import React from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import video1 from "./images/video1.mp4";

export default function AboutUs() {
  // const [showVideo, setShowVideo] = useState(true);
  // const handleDevice = () => {
  //   if (screen.width < 600) {
  //     setShowVideo(false);
  //   } else {
  //     setShowVideo(true);
  //   }
  // };

  let video;
  if (window.innerWidth > 600) {
    video = (
      <video
        muted
        loop
        src={video1}
        width="100%"
        autoplay="true"
        className="video just-web"
      />
    );
  } else {
    video = null;
  }
  return (
    <div>
      <div className="portada-about portada-gral">
        <h1 className="titular just-web">
          ALP Importaciones: <br /> pasión por el servicio
        </h1>
        <h1 className="titular just-mobile">
          ALP: pasión por <br /> el servicio
        </h1>
        <h3 className="texto-portada just-web">
          Conocé más de nosotros, tan aficionados de la madera <br /> como vos.
        </h3>
        <h3 className="texto-portada just-mobile">
          Conocé más de nosotros, tan <br /> aficionados de la madera como
          <br /> vos.
        </h3>
      </div>
      <div className="view2">
        <h2 className="h2-portada just-web">
          Somos una empresa joven, vinculada al sector de la <br /> fabricación
          de muebles y todo lo relacionado con la <br /> industria de la madera.
        </h2>
        <h2 className="h2-portada just-mobile">
          Somos una empresa joven, <br /> vinculada al sector de la <br />{" "}
          fabricación de muebles y todo <br /> lo relacionado con la industria{" "}
          <br /> de la madera.
        </h2>
        <div className="container-about2">
          <div className="about2-left">
            <h3 className="h3-aboutus">ALP Importaciones</h3>
            <p className="text-aboutus just-web">
              Con más de 200 máquinas vendidas e <br /> instaladas en Uruguay,
              hacemos <br />
              mucho foco en el servicio postventa. <br /> Contamos con 3
              técnicos capacitados <br /> y con herramientas de alta tecnología
              <br /> para el mantenimiento de los equipos.
            </p>
            <p className="text-aboutus just-mobile">
              Con más de 200 máquinas vendidas e <br /> instaladas en Uruguay,
              hacemos mucho foco <br /> en el servicio postventa. Contamos con 3{" "}
              <br />
              técnicos capacitados y con herramientas de <br /> alta tecnología
              para el mantenimiento de los <br /> equipos.
            </p>
            <br />
            <p className="text-aboutus just-web">
              Nuestro compromiso principal no es <br /> con la venta, es que las
              máquinas que <br /> ya están operando en Uruguay no <br /> paren
              nunca, se mantengan tal como <br /> define la fábrica y sostengan
              un valor <br /> residual que permita a nuestros <br /> clientes
              seguir incorporando <br /> tecnología de punta a futuro.
            </p>
            <p className="text-aboutus just-mobile">
              Nuestro compromiso principal no es con la <br /> venta, es que las
              máquinas que ya están <br /> operando en Uruguay no paren nunca,
              se <br /> mantengan tal como define la fábrica y <br /> sostengan
              un valor residual que permita a <br /> nuestros clientes seguir
              incorporando <br /> tecnología de punta a futuro.
            </p>
            <br />
            <NavLink to="/contact">
              <button className="contact-button"> CONTÁCTESE AQUÍ </button>
            </NavLink>
          </div>
          <div className="about2-right">
            <img src={require("./images/maquinas01.png")} alt="" className="" />
            <img src={require("./images/maquinas02.png")} alt="" className="" />
          </div>
        </div>
      </div>
      <div className="aboutus-components">
        <div className="video-container">
          {/* <video
            muted
            loop
            src={video1}
            width="100%"
            autoplay="true"
            className="video just-web"
          /> */}
          {video}
        </div>
      </div>
      <hr />
      <footer>
        <p>Copyright 2022</p>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/ALPImportaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="https://www.instagram.com/alp_importaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>

          <a
            href="https://www.linkedin.com/company/alp-importaciones/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </footer>
    </div>
  );
}
