import React from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import Carousel from "./Carousel";

export default function Products() {
  return (
    <div className="total">
      <div className="portada-products portada-gral">
        <h1 className="titular just-web">
          Los mejores productos <br /> con la mejor calidad
        </h1>
        <h1 className="titular just-mobile">
          Los mejores <br /> productos con la <br /> mejor calidad
        </h1>
        <h3 className="texto-portada just-web">
          Descubrí todo el catálogo de maquinaria disponible para la
          importación.
        </h3>
        <h3 className="texto-portada just-mobile">
          Descubrí todo el catálogo de maquinaria <br /> disponible para la
          importación.
        </h3>
      </div>
      <h2 className="h2-portada just-mobile">
        Todo lo que necesita para su <br /> negocio. Desde escuadradoras, <br />{" "}
        hasta centros de trabajo <br /> completamente automatizados y <br />{" "}
        accesorios para su taller.
      </h2>
      <h2 className="h2-portada just-web">
        Todo lo que necesita para su negocio. Desde <br /> escuadradoras, hasta
        centros de trabajo completamente <br /> automatizados y accesorios para
        su taller.
      </h2>
      <Carousel />
      <div id="button-container">
        <a
          href="https://www.felder-group.com/es-uy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="contact-button">VER CATÁLOGO</button>
        </a>
      </div>
      <hr />
      <footer>
        <p>Copyright 2022</p>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/ALPImportaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare className="s-icon" />
          </a>
          <a
            href="https://www.instagram.com/alp_importaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="s-icon" />
          </a>

          <a
            href="https://www.linkedin.com/company/alp-importaciones/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="s-icon" />
          </a>
        </div>
      </footer>
    </div>
  );
}
