import React from "react";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import "./HomeMobile.css";

export default function Home() {
  return (
    <div className="total">
      {/* <img src={require("./images/Group14.jpg")} alt="" className="img-background"/> */}

      <div className="portada portada-gral">
        <div className="portada1">
          <h1 className="titular">
            De Austria a <br />
            tu carpintería
          </h1>
          <p className="texto-portada">
            Representantes exclusivos para Uruguay del
            <br /> Felder Group Austria. Importá toda la maquinaria <br /> y
            accesorios que necesitás para tu negocio.
          </p>
          <NavLink to="/contact">
            <button className="contact-button"> CONTACTARSE </button>
          </NavLink>
        </div>
        {/* <img
          className="felder-logo"
          src={require("./images/felder-group-logo.png")}
          alt=""
        /> */}
      </div>
      {/* <div className="portada-2">
          <img
            src={require("./images/smiling-guy.jpg")}
            alt=""
            className="smiling-guy"
          />
        </div> */}
      <div className="view2">
        <h2 className="h2-portada just-web">
          Somos una empresa que nace para elevar <br /> el nivel de tu
          carpintería
        </h2>
        <h2 className="h2-portada just-mobile">
          Somos una empresa que <br /> nace para elevar el nivel de <br /> tu
          carpintería
        </h2>
        <div className="info-container">
          <img
            src={require("./images/circular.jpg")}
            alt=""
            className="img-circular just-web"
          />
          <div className="info-text just-web">
            <p>
              Contamos con el catálogo completo de uno de los <br />
              principales fabricantes de máquinas para trabajar la <br /> madera
              del mercado europeo. El grupo cuenta con <br /> cuatro líneas de
              máquinas, cada una con su marca, <br /> todas ellas fabricadas en
              la planta de Hall in Tirol en <br /> Austria. <br />
            </p>{" "}
            <p>
              Ya sea para pequeños o grandes proyectos, <br /> contamos con la
              máquina para vos.
            </p>
            <a href="https://www.felder-group.com/es-uy">
              <button className="button-white"> SABER MÁS</button>
            </a>
          </div>
          <div className="info-text just-mobile">
            <p>
              Contamos con el catálogo completo de uno <br /> de los principales
              fabricantes de máquinas <br /> para trabajar la madera del mercado
              <br />
              europeo. El grupo cuenta con cuatro líneas de <br /> máquinas,
              cada una con su marca, todas ellas <br /> fabricadas en la planta
              de Hall in Tirol en <br /> Austria. <br />
              <br />
              Ya sea para pequeños o grandes proyectos, <br /> contamos con la
              máquina para vos.
            </p>
            <a href="https://www.felder-group.com/es-uy">
              <button className="button-white"> SABER MÁS</button>
            </a>
          </div>
        </div>
      </div>
      <div className="marcas">
        <h2>Nuestras marcas</h2>
        <div className="marcas-container">
          <div className="marca-felder">
            <img src={require("./images/felder.png")} alt="" />
            <p className="just-web">
              Línea industrial de alta gama. Máquinas pesadas, <br /> robustas,
              potentes, super precisas y de altísima <br /> calidad de
              construcción. Tiene la posibilidad de <br /> incorporar control
              numérico a cada uno de sus <br /> modelos.
            </p>
            <p className="just-mobile">
              Línea industrial de alta gama. <br /> Máquinas pesadas, robustas,
              potentes, <br /> super precisas y de altísima calidad de <br />
              construcción. Tiene la posibilidad de <br /> incorporar control
              numérico a cada uno <br /> de sus modelos.
            </p>
          </div>
          <div className="marca-format4">
            <img src={require("./images/format4.png")} alt="" />
            <p className="just-web">
              Línea industrial de alta gama con control <br /> numérico.
              Máquinas de altísimo nivel de calidad, <br /> todas ellas con
              control numérico desde sus <br /> versiones estándar. Chasis ultra
              pesados, <br /> robustos, calidad, precisión y repetibilidad{" "}
              <br /> inigualables.
            </p>
            <p className="just-mobile">
              Línea industrial de alta gama con control <br /> numérico.
              Máquinas de altísimo nivel de <br /> calidad, todas ellas con
              control numérico <br /> desde sus ersiones estándar. Chasis <br />
              ultra pesados, robustos, calidad, precisión <br /> y repetibilidad
              inigualables.
            </p>
          </div>
          <div className="marca-hammer">
            <img src={require("./images/hammer.png")} alt="" />
            <p className="just-mobile">
              Es la línea profesional y más accesible de <br /> la marca. Con
              Hammer® se garantiza que <br /> cada proyecto sea un éxito.
              Probado miles <br /> de veces y excepcionalmente simple de <br />
              usar. Hammer®: calidad profesional <br /> desde Austria.
            </p>
            <p className="just-web">
              Es la línea profesional y más accesible de la marca. <br /> Con
              Hammer® se garantiza que cada proyecto sea <br /> un éxito.
              Probado miles de veces y <br /> excepcionalmente simple de usar.
              <br /> Hammer®: calidad profesional desde Austria.
            </p>
          </div>

          <div className="marca-mayer">
            <img src={require("./images/mayer.png")} alt="" />
            <p className="just-web">
              Línea enfocada solamente en la fabricación de <br /> seccionadoras
              ultra pesadas. Las mejores <br /> seccionadoras del mundo, para
              todo tipo de <br /> materiales. Se fabrican completamente en la{" "}
              <br /> fábrica ubicada en Lossburg, Alemania.
            </p>
            <p className="just-mobile">
              Línea enfocada solamente en la <br /> fabricación de seccionadoras
              ultra <br /> pesadas. Las mejores seccionadoras del <br /> mundo,
              para todo tipo de materiales. Se <br /> fabrican completamente en
              la fábrica <br /> ubicada en Lossburg, Alemania.
            </p>
          </div>
        </div>
        <a href="/products" className="contact-button">
          VER PRODUCTOS
        </a>
      </div>
      <div className="view4">
        <div className="view4-left">
          <h2>¿Cómo funciona?</h2>
          <div>
            <p className="just-web">
              Comprar e importar a través de ALP <br /> Importaciones es tan
              simple como <br />
              comprar desde tu casa. Además, <br /> contamos con el mejor
              servicio post <br />
              venta del país
            </p>
            <p className="just-mobile">
              Comprar e importar a través de ALP <br /> Importaciones es tan
              simple como comprar <br /> desde tu casa. Además, contamos con el
              <br /> mejor servicio post venta del país
            </p>
          </div>
          <div className="servgaran">
            <img
              src={require("./images/icon-tick.png")}
              alt=""
              className="icon-left4"
            />
            <p className="just-mobile">
              Todas las máquinas son instaladas por <br /> nuestros técnicos,
              asegurando la garantía <br /> de fábrica.
            </p>
            <p className="just-web">
              Todas las máquinas son <br /> instaladas por nuestros técnicos,
              <br /> asegurando la garantía de fábrica.
            </p>
          </div>
          <div className="servgaran">
            <img
              src={require("./images/icon-herramienta.png")}
              alt=""
              className="icon-left4"
            />
            <p className="just-web">
              El 1er servicio de mantenimiento <br /> preventivo está incluido
              dentro <br /> del precio de venta.
            </p>
            <p className="just-mobile">
              El 1er servicio de mantenimiento preventivo <br /> está incluido
              dentro del precio de venta.
            </p>
          </div>
          <img
            src={require("./images/craftsman-creating-wood-piece.jpg")}
            alt=""
            className="img-craftsman"
          />
        </div>
        <div className="view4-right">
          <div className="view4-pasos">
            <div className="titular-pasos">
              <img src={require("./images/icon-lupa.png")} alt="" />
              <h2>1</h2>
            </div>
            <p className="just-web">
              Encontrá en nuestro catálogo o con nuestro asesor <br /> la
              máquina que más se adecúa a tus necesidades y <br />
              presupuesto. Configurala enteramente a tu <br /> necesidad o
              gusto. Cada equipo cuenta con <br /> muchos ítems configurables,
              nuestros asesores <br /> están para apoyarte en este camino.
            </p>
            <p className="just-mobile">
              Encontrá en nuestro catálogo o con nuestro <br /> asesor la
              máquina que más se adecúa a tus <br /> necesidades y presupuesto.
              <br /> <br />
              Configurala enteramente a tu necesidad o gusto. <br /> Cada equipo
              cuenta con muchos ítems <br /> configurables, nuestros asesores
              están para <br /> apoyarte en este camino.
            </p>
          </div>
          <div className="view4-pasos">
            <div className="titular-pasos">
              <img src={require("./images/icon-pago.png")} alt="" />
              <h2>2</h2>
            </div>
            <p>
              Una vez configurado el equipo, procedemos a la <br /> firma de un
              contrato para establecer formas de <br /> pago, tiempos de
              entrega, responsabilidades del <br /> vendedor y del comprador.
            </p>
          </div>
          <div className="view4-pasos">
            <div className="titular-pasos">
              <img src={require("./images/icon-barco.png")} alt="" />
              <h2>3</h2>
            </div>
            <p>
              Tu máquina será importada en el próximo <br /> contenedor hacia
              Uruguay.
            </p>
          </div>
          <div className="view4-pasos">
            <div className="titular-pasos">
              <img src={require("./images/icon-paquete.png")} alt="" />
              <h2>4</h2>
            </div>
            <p className="just-web">
              El contenedor llega al puerto de Montevideo, donde <br /> cada
              comprador debe retirarlo pagando los <br /> impuestos
              correspondientes.
            </p>
            <p className="just-mobile">
              El contenedor llega al puerto de Montevideo, <br /> donde cada
              comprador debe retirarlo pagando <br /> los impuestos
              correspondientes.
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }} className="view5">
        <div className="review">
          <h2>
            “Cita de cliente contento <br />
            con ALP Importaciones”
          </h2>
          <h3>
            Cliente 1 <br /> Montevideo, Uruguay
          </h3>
        </div>
      </div>
      <div className="view6">
        <div className="slogan">
          <h2 className="just-web">
            Dé un salto de calidad y transforme <br />
            su negocio.
          </h2>
          <h2 className="just-mobile">
            Dé un salto de calidad y <br /> transforme su negocio.
          </h2>
        </div>
        <NavLink to="/contact">
          <button className="contact-button"> CONTACTARSE </button>
        </NavLink>
      </div>
      <hr />
      <footer>
        <p>Copyright 2022</p>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/ALPImportaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare className="s-icon" />
          </a>
          <a
            href="https://www.instagram.com/alp_importaciones/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="s-icon" />
          </a>

          <a
            href="https://www.linkedin.com/company/alp-importaciones/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="s-icon" />
          </a>
        </div>
      </footer>
    </div>
  );
}
