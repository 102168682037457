import React from "react";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div>
      <ul className="navbar-list">
        <li className="just-web">
          <NavLink to="/about-us">Quiénes somos</NavLink>
        </li>
        <li className="just-web">
          <NavLink to="/products">Productos</NavLink>
        </li>
        <li className="just-web">
          <NavLink to="/contact">Contacto</NavLink>
        </li>
      </ul>
    </div>
  );
}
