import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Slidebar.css";

const Slidebar = () => {
  //clases burger menu
  const [burger_class, setBurger_class] = useState("burger-bar unclicked");
  const [menu_class, setMenu_class] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  //update
  const updateMenu = () => {
    if (isMenuClicked) {
      setBurger_class("burger-bar clicked");
      setMenu_class("menu visible");
    } else {
      setBurger_class("burger-bar unclicked");
      setMenu_class("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };
  const handleMenu = () => {
    if (menu_class === "menu visible") {
      setBurger_class("burger-bar unclicked");
      setMenu_class("menu hidden");
    } else if (menu_class === "menu hidden") {
      setMenu_class("menu visible");
      setBurger_class("burger-bar clicked");
    }
  };
  const hideMenu = () => {
    setBurger_class("burger-bar unclicked");
    setMenu_class("menu hidden");
  };

  return (
    <div style={{ position: "absolute" }}>
      <nav>
        <NavLink to="/home" onClick={hideMenu}>
          <img
            src={require("./images/Group.png")}
            alt=""
            className="alp-logo"
          />
        </NavLink>
        <div className="burger-menu" onClick={updateMenu}>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
        </div>
      </nav>
      <div className={menu_class}>
        <ul>
          <li className="burger-item" onClick={handleMenu}>
            <NavLink to="/about-us">Quiénes somos</NavLink>
          </li>
          <li className="burger-item">
            <NavLink to="/products" onClick={handleMenu}>
              Productos
            </NavLink>
          </li>
          <li className="burger-item">
            <NavLink to="/contact" onClick={handleMenu}>
              Contacto
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Slidebar;
